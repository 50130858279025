/*========================================================
        DARK LAYOUT
=========================================================*/
.btn, .fc .fc-button {
  display: inline-block;
  font-weight: 400;
  color: #626262;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.9rem 2rem;
  font-size: 1rem;
  border-radius: 0.42rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .fc .fc-button {
      transition: none; } }
  .btn:hover, .fc .fc-button:hover {
    color: #626262;
    text-decoration: none; }
  .btn:focus, .fc .fc-button:focus, .btn.focus, .fc .focus.fc-button {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(115, 103, 240, 0.25); }
  .btn.disabled, .fc .disabled.fc-button, .btn:disabled, .fc .fc-button:disabled {
    opacity: 0.65; }

a.btn.disabled, .fc a.disabled.fc-button,
fieldset:disabled a.btn,
fieldset:disabled .fc a.fc-button,
.fc fieldset:disabled a.fc-button {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3; }
  .btn-primary:hover {
    color: #fff;
    background-color: #5344ed;
    border-color: #4839eb; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(136, 126, 242, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4839eb;
    border-color: #3e2dea; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(136, 126, 242, 0.5); }

.btn-secondary {
  color: #2a2e30;
  background-color: #b8c2cc;
  border-color: #b8c2cc; }
  .btn-secondary:hover {
    color: #2a2e30;
    background-color: #a2afbc;
    border-color: #9aa9b7; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #2a2e30;
    background-color: #b8c2cc;
    border-color: #b8c2cc; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #2a2e30;
    background-color: #9aa9b7;
    border-color: #93a2b1; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28c76f;
  border-color: #28c76f; }
  .btn-success:hover {
    color: #fff;
    background-color: #22a75d;
    border-color: #1f9d57; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 207, 133, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28c76f;
    border-color: #28c76f; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1f9d57;
    border-color: #1d9251; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 207, 133, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00cfe8;
  border-color: #00cfe8; }
  .btn-info:hover {
    color: #fff;
    background-color: #00adc2;
    border-color: #00a1b5; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 214, 235, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a1b5;
    border-color: #0096a8; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 214, 235, 0.5); }

.btn-warning {
  color: #2a2e30;
  background-color: #ff9f43;
  border-color: #ff9f43; }
  .btn-warning:hover {
    color: #2a2e30;
    background-color: #ff8b1d;
    border-color: #ff8510; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 142, 64, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #2a2e30;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #2a2e30;
    background-color: #ff8510;
    border-color: #ff7e03; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 142, 64, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455; }
  .btn-danger:hover {
    color: #fff;
    background-color: #e63233;
    border-color: #e42728; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 110, 111, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e42728;
    border-color: #e21c1d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 110, 111, 0.5); }

.btn-light {
  color: #2a2e30;
  background-color: #babfc7;
  border-color: #babfc7; }
  .btn-light:hover {
    color: #2a2e30;
    background-color: #a5abb6;
    border-color: #9ea5b0; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(164, 169, 176, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #2a2e30;
    background-color: #babfc7;
    border-color: #babfc7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #2a2e30;
    background-color: #9ea5b0;
    border-color: #979eaa; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(164, 169, 176, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #1e1e1e; }
  .btn-dark:hover {
    color: #fff;
    background-color: #0b0b0b;
    border-color: #050505; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #1e1e1e;
    border-color: #1e1e1e; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #050505;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-outline-primary, .fc .fc-button {
  color: #2196F3;
  border-color: #2196F3; }
  .btn-outline-primary:hover, .fc .fc-button:hover {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-outline-primary:focus, .fc .fc-button:focus, .btn-outline-primary.focus, .fc .focus.fc-button {
    box-shadow: 0 0 0 0.2rem rgba(115, 103, 240, 0.5); }
  .btn-outline-primary.disabled, .fc .disabled.fc-button, .btn-outline-primary:disabled, .fc .fc-button:disabled {
    color: #2196F3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .fc .fc-button:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .fc .fc-button:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle,
  .fc .show > .dropdown-toggle.fc-button {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .fc .fc-button:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .fc .fc-button:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus,
    .fc .show > .dropdown-toggle.fc-button:focus {
      box-shadow: 0 0 0 0.2rem rgba(115, 103, 240, 0.5); }

.btn-outline-secondary {
  color: #b8c2cc;
  border-color: #b8c2cc; }
  .btn-outline-secondary:hover {
    color: #2a2e30;
    background-color: #b8c2cc;
    border-color: #b8c2cc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(184, 194, 204, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #b8c2cc;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #2a2e30;
    background-color: #b8c2cc;
    border-color: #b8c2cc; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(184, 194, 204, 0.5); }

.btn-outline-success {
  color: #28c76f;
  border-color: #28c76f; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28c76f;
    border-color: #28c76f; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 199, 111, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28c76f;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28c76f;
    border-color: #28c76f; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 199, 111, 0.5); }

.btn-outline-info {
  color: #00cfe8;
  border-color: #00cfe8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 232, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00cfe8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00cfe8;
    border-color: #00cfe8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 207, 232, 0.5); }

.btn-outline-warning {
  color: #ff9f43;
  border-color: #ff9f43; }
  .btn-outline-warning:hover {
    color: #2a2e30;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 159, 67, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff9f43;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #2a2e30;
    background-color: #ff9f43;
    border-color: #ff9f43; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 159, 67, 0.5); }

.btn-outline-danger {
  color: #ea5455;
  border-color: #ea5455; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 84, 85, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ea5455;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 84, 85, 0.5); }

.btn-outline-light {
  color: #babfc7;
  border-color: #babfc7; }
  .btn-outline-light:hover {
    color: #2a2e30;
    background-color: #babfc7;
    border-color: #babfc7; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 191, 199, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #babfc7;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #2a2e30;
    background-color: #babfc7;
    border-color: #babfc7; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 191, 199, 0.5); }

.btn-outline-dark {
  color: #1e1e1e;
  border-color: #1e1e1e; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #1e1e1e;
    border-color: #1e1e1e; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 30, 30, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1e1e1e;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1e1e1e;
    border-color: #1e1e1e; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(30, 30, 30, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2196F3;
  text-decoration: none; }
  .btn-link:hover {
    color: #5e50ee;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #b8c2cc;
    pointer-events: none; }

.btn-lg {
  padding: 1rem 2.5rem;
  font-size: 1.25rem;
  border-radius: 0.4285rem; }

.btn-sm {
  padding: 0.5rem 1.5rem;
  font-size: 0.7rem;
  border-radius: 0.4285rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fc .fc-header-toolbar {
  margin-bottom: 2rem; }
  .fc .fc-header-toolbar .bullets-group-1,
  .fc .fc-header-toolbar .bullets-group-2 {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin: .5rem auto; }
  .fc .fc-header-toolbar .bullets-group-2 {
    margin-bottom: 0; }
  .fc .fc-header-toolbar .fc-right div:first-child {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .fc .fc-header-toolbar .fc-right div:first-child h2 {
      font-weight: 400;
      font-size: 1.3rem;
      margin: 0 0.5rem; }
  .fc .fc-header-toolbar .fc-right .fc-button {
    border-radius: 50%;
    padding: .4rem .4rem;
    height: 24px;
    width: 24px; }
    .fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button {
      margin-right: 0.5rem; }
      .fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button .fc-icon {
        position: relative;
        top: -6px;
        left: -4px; }
    .fc .fc-header-toolbar .fc-right .fc-button.fc-next-button {
      margin-left: 0.5rem; }
      .fc .fc-header-toolbar .fc-right .fc-button.fc-next-button .fc-icon {
        position: relative;
        top: -6px;
        right: 3px; }
  .fc .fc-header-toolbar .fc-addNew-button {
    padding: .65rem 2rem; }
    .fc .fc-header-toolbar .fc-addNew-button:before {
      font-family: "feather";
      content: "\e8b1"; }

.fc .fc-view-container .fc-head .fc-head-container thead .fc-day-header {
  padding: 1px 0; }
  .fc .fc-view-container .fc-head .fc-head-container thead .fc-day-header.fc-today {
    color: #000; }

.fc .fc-view-container .fc-body .fc-week table tbody .fc-day {
  cursor: pointer; }
  .fc .fc-view-container .fc-body .fc-week table tbody .fc-day.fc-today {
    background: transparent; }

.fc .fc-view-container .fc-body .fc-week table tbody .fc-other-month {
  background-color: #f1f1f1; }

.fc .fc-view-container .fc-body .fc-day-top.fc-today a {
  background-color: #2196F3;
  color: #fff;
  padding: 5px 10px;
  margin-top: 2px;
  margin-right: 2px;
  border-radius: 50%; }

.fc .fc-view-container .fc-body .fc-not-end,
.fc .fc-view-container .fc-body .fc-not-start {
  padding: 1px 8px !important;
  opacity: 1 !important; }
  .fc .fc-view-container .fc-body .fc-not-end .fc-title,
  .fc .fc-view-container .fc-body .fc-not-start .fc-title {
    padding-left: 1rem; }

.fc .fc-view-container .fc-body .fc-not-start {
  margin-left: -6px !important; }

.fc .fc-view-container .fc-body .fc-not-end {
  margin-right: -6px !important; }

.fc .fc-view-container td,
.fc .fc-view-container th {
  border-color: #e0e0e0;
  font-size: 1rem; }

.fc .fc-button {
  padding: 0.6rem 1rem;
  height: auto;
  outline: none;
  text-shadow: none;
  background-color: #2196F3;
  color: #fff; }
  .fc .fc-button:not(:disabled).fc-button-active {
    background-color: #4839eb; }
  .fc .fc-button:focus {
    outline: none;
    box-shadow: none; }

.fc .fc-event {
  background: #2196F3;
  border: 1px solid #5e50ee;
  padding: 1px 8px;
  border-radius: 1rem;
  border: none;
  padding-left: 1rem; }
  .fc .fc-event .fc-title {
    font-size: .85rem;
    color: #fff; }

.modal-calendar .calendar-dropdown .dropdown-toggle {
  font-size: 1.2rem; }
  .modal-calendar .calendar-dropdown .dropdown-toggle:after {
    display: none; }

.modal-calendar #cal-start-date:disabled {
  opacity: .5; }

@media (max-width: 900px) {
  .fc .fc-header-toolbar {
    margin-bottom: 2rem; }
    .fc .fc-header-toolbar .bullets-group-1 [class*="category-"] {
      margin: .3rem 0; } }

@media (max-width: 767.98px) {
  .fc .fc-header-toolbar {
    flex-direction: column;
    margin-bottom: 2rem; }
    .fc .fc-header-toolbar .bullets-group-1 {
      display: none; }
    .fc .fc-header-toolbar .fc-left {
      margin-bottom: 1rem; }
    .fc .fc-header-toolbar .fc-center {
      margin: 1 auto;
      margin-bottom: 0; }
    .fc .fc-header-toolbar .fc-right {
      margin: 1rem auto; }
      .fc .fc-header-toolbar .fc-right div:first-child {
        justify-content: center; } }
