/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~ngx-toastr/toastr";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../node_modules/@busacca/ng-pick-datetime/assets/style/picker.min.css";


$pending-confirmation-color: #999999;
$confirmed-pending-color: #03A9F4;
$done-pending-validation-color: #a64d79;
$done-validated-color: #6aa84f;
$cancelled-color: #000000;
$base-color:#03A9F4;;

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 200;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Thin.otf);
}

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 300;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Light.otf);
}

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 400;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Regular.otf);
}

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 500;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Medium.otf);
}

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 600;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Semibold.otf);
}

@font-face {
	font-family: 'GesemeAtypBL';
	font-style: normal;
	font-weight: 700;
	src: url(assets/app-assets/fonts/geseme-atypbltext/Geseme-AtypBLText-Bold.otf);
}

ul,
li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.text-danger {
	font-size: 11px;
}

.border-danger {
	border-radius: 5px;

	.ng-select-container {
		border: none;
	}
}

.btn-outline-primary {
	border: 1px solid $base-color;
}

.modal-fade-animation {
	animation-name: modal-fade-animation;
	animation-duration: 0.6s;
}

.main-menu.menu-light .navigation {
	height: 100%;
}

.main-menu .navbar-header {
	padding: 0.35rem 1rem 0.3rem 1.10rem;
}

.main-menu ul li {
	width: auto;
}

@keyframes modal-fade-animation {
	from {
		transform: translate3d(0, -20%, 0);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop,
.cdk-overlay-container,
.cdk-overlay-pane,
.owl-dt-container {
	z-index: 1500;
}

.form-group {
	margin-bottom: 1rem;
}

textarea {
	resize: none;
}

.modal {
	.modal-header {
		.close {
			padding: 0;
			box-shadow: none;
			border-radius: 0px;
			background: transparent;
			opacity: 1;
			-webkit-transition: all 0.23s ease 0.1s;
			transition: all 0.23s ease 0.1s;
			position: relative;
			-webkit-transform: inherit;
			transform: inherit;
			margin: 0;

			&:hover,
			&:focus,
			&:active {
				-webkit-transform: inherit;
				transform: inherit;
			}
		}
	}

	.modal-footer {
		border: none;
		padding: 0 1rem 1rem;
	}
}

button.btn-default {
	&:hover {
		background: #f6f6f6;
		box-shadow: 0 8px 25px -8px #cecece;
	}
}

.error-text {
	color: red;
	font-weight: bold
}

.w100 {
	width: 100%;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: inherit !important;
}

.pending-confirmation {
	background-color: $pending-confirmation-color;
}

.confirmed-pending {
	background-color: $confirmed-pending-color;
}

.done-pending-validation {
	background-color: $done-pending-validation-color !important;
}

.done-validated {
	background-color: $done-validated-color;
}

.cancelled {
	background-color: $cancelled-color;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #b8c2cc !important;
	border-color: #b8c2cc !important;
	cursor: not-allowed;

	&:hover {
		box-shadow: 0 8px 25px -8px #6c757d;
	}
}

form-wizard {
	.card {
		padding: 0px;
		box-shadow: none;
		margin-bottom: 0px;

		.card-header {
			margin-bottom: 20px;

			ul.nav {
				width: 100%;

				li.nav-item {
					padding: 0.5rem 0rem;
					padding-right: 5px;
					padding-left: 5px;

					a {
						font-size: 13px;
					}

					&.enabled {
						a {
							color: rgb(88, 162, 234);
						}
					}

					&.active {
						a {
							color: #1976d2;
						}
					}
				}
			}
		}

		.card-block {
			overflow-y: inherit;

			ul.list {
				margin: 15px 0px;
				padding: 0;
				overflow-x: hidden;
				height: 250px;

				li {
					padding: 8px 10px;
					margin-bottom: 5px;
					cursor: pointer;

					h3 {
						font-size: 15px;
						color: #fff;
					}

					h5 {
						font-size: 12px;
						color: #fff;
						margin: 0;
					}
				}
			}

			.vs-checkbox-con {
				margin-top: 28px;
			}
		}

		.card-footer {
			padding: 0rem;

			button {
				color: #fff;
				background-color: $base-color !important;
				border-color: $base-color !important;

				&:disabled {
					background-color: #b8c2cc !important;
					border-color: #b8c2cc !important;
				}
			}
		}
	}

	.additional-footer-buttons {
		>.btn {
			margin-right: 0.5rem;
		}

		&.to-left {
			position: absolute;
			bottom: 0;
			left: 0;
			margin-bottom: 0;

			>.btn {
				flex: inherit;
			}
		}
	}

	.plandepreve-footer-buttons {
		>.btn {
			margin-right: 0.5rem;
		}

		&.to-left {
			position: absolute;
			bottom: 0;
			left: 0;
			margin-bottom: 0;

			>.btn {
				flex: inherit;
			}
		}
	}
}

@media (min-width: 991.99px) {
	form-wizard {
		.additional-footer-buttons {
			position: absolute;
			bottom: 0;
			right: 124px;
		}
	}
}

@media (min-width: 991.99px) {
	form-wizard {
		.plandepreve-footer-buttons {
			position: absolute;
			bottom: 0;
			right: 4px;
		}
	}
}

@media (max-width: 991.98px) {
	form-wizard {
		.additional-footer-buttons {
			margin-bottom: 0.5rem;
			display: flex;
			width: 100%;

			>.btn {
				flex: 1;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

@media (max-width: 991.98px) {
	form-wizard {
		.plandepreve-footer-buttons {
			margin-bottom: 0.5rem;
			display: flex;
			width: 100%;

			>.btn {
				flex: 1;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.activity-list {
	li {
		color: #000;
		overflow: hidden;
		margin-bottom: 5px;
		cursor: pointer;

		.date-time {
			float: left !important;
			text-align: center !important;
			padding: 7px 15px 7px 0 !important;
			background: #fff !important;

			span {
				display: block !important;
			}
		}

		.info {
			float: left;
			padding: 7px 0px 7px 10px;

			h3 {
				font-size: 15px;
				color: #fff;
			}

			h5 {
				font-size: 12px;
				margin: 0px;
				color: #fff;
			}
		}
	}
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	width: 17em;
}

app-activity-mini-calendar {
	.owl-dt-inline {
		display: block;
	}

	.owl-dt-inline-container,
	.owl-dt-popup-container {
		width: 100%;
		box-shadow: none;
		z-index: 1;
	}

	.owl-dt-inline-container .owl-dt-calendar,
	.owl-dt-popup-container .owl-dt-calendar {
		height: 100%;
	}
}

.vs-checkbox-con .vs-checkbox {
	border-width: 1px;
}

.scrollbar {
	overflow-y: scroll;
	margin: 10px;
	padding: 0 10px;
}

#blue-bar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#blue-bar::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

#blue-bar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: $base-color;
}

.alert {
	overflow: hidden;
	text-align: center;
}

@media (max-width: 320px) {
	.modal-footer {

		.activity-add-event,
		.cancel-event,
		.activity-delete-event {
			padding: 13px;
		}
	}

	.mobile-p-t-24 {
		padding-top: 24px;
	}

	.mobile-horiz-h {
		max-height: 300px;
		overflow: scroll;
	}

	.usr-detail-mobile {
		width: 58px !important;
	}

	#modal-activity-multiple .card-footer .btn {
		width: 100%;
	}
}

// Change this to set unique styles for 'visita' type events
.event-type-visit {
	border: 2px solid #0081bb !important;
	background-color: #03A9F4 !important;
}

.visit-cancelled {
	background-color: #202020 !important;
	border: 2px solid #696969 !important;
}

.visit-confirmed_pending {
	background-color: #03A9F4 !important;
	border: 2px solid #0081bb !important;
}

.visit-pending {
	background-color: #999999 !important;
	border: 2px solid #7b7a7a !important;
}

.visit-done {
	background-color: #6aa84f !important;
	border: 2px solid #4b7d35 !important;
}

.visit-done_pending {
	background-color: #a64d79 !important;
	border: 2px solid #773054 !important;
}

@media (max-width: 450px) {
	.usr-detail-mobile {
		width: 90px;
	}

	.mobile-horiz-h {
		max-height: 640px !important;
		overflow: scroll;
	}

	#modal-activity-multiple .card-footer .btn {
		width: 100%;
		margin-bottom: 10px;
	}
}

@media (min-width: 560px) {
	.rememberme {
		display: flex;
	}

	.mobile-p-t-24 {
		padding-top: 24px;
	}
}

@media (max-width: 750px) {
	.mobile-horiz-h {
		max-height: 300px;
		overflow: scroll;
	}
}

@media (max-width: 1280px) {

	body.vertical-layout.vertical-menu-modern.menu-open .main-menu,
	body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
		left: 0;
		width: 260px;
		opacity: 1;
	}

	body.menu-open .sidenav-overlay {
		display: block;
	}
}

@media (max-width: 575.98px) {
	.mobile-w-100 {
		width: 100%;
	}

	body.vertical-layout.vertical-menu-modern.menu-open .header-navbar.floating-nav {
		width: calc(100vw - (100vw - 100%) - calc(2.2rem * 2) - 0px);
	}

	.header-navbar.floating-nav {
		width: calc(100vw - (100vw - 100%) - 33px) !important;
		margin-left: 0;
		margin-right: 17px;
	}

	body.menu-open .sidenav-overlay {
		display: block;
	}

	.header-navbar .navbar-container {
		padding-left: 0px !important;
	}

	.bookmark-wrapper .btn {
		padding: 0.9rem 8px;
		font-size: 0.77em;
		margin-right: 6px !important;
	}

	.rememberme {
		display: block;

		.checkbox {
			margin-bottom: 5px;
		}
	}

	.ext-activity-pagination {
		font-size: 9px;
	}

	form-wizard .card .card-header ul.nav li a {
		font-size: 11px !important;
	}

	.mobile-p-t-24 {
		padding-top: 24px;
	}
}

.pagination {
	.card-body {
		height: auto !important;

		ul {
			margin-bottom: 0 !important;
			float: right;
		}
	}
}

#dvLoggedUser {
	margin: 12px 10px 0px 10px;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		cursor: pointer;
	}

	.user-details {
		text-align: end;

		h5 {
			font-weight: 600;
			font-size: 14px !important;
			color: #626262;
		}

		h6 {
			font-size: 12px !important;
			color: #626262;
			padding-top: 5px;
		}
	}
}

.ext-activity-pagination {
	margin-top: 20px;

	.card-body {
		padding: 0px !important;

		ul {
			margin: 10px 0px !important;
		}
	}
}

.unauthorize-div {
	padding: 30px;

	h6 {
		font-weight: normal;
	}
}

.fc .fc-event {
	border: 2px solid;
	padding: 1px 16px;
}

.main-menu .main-menu-content {
	height: 100vh;
}

.filter-list {
	ng-select {
		display: inline-grid;
		float: right;

		&:nth-child(1) {
			width: 60px;
		}

		&:nth-child(2) {
			width: 130px;
			margin-right: 10px;
		}
	}
}

.ng-dropdown-panel,
.tbl-actuaciones {
	.scroll-host::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #F5F5F5;
	}

	.scroll-host::-webkit-scrollbar {
		width: 5px;
		background-color: #F5F5F5;
	}

	.scroll-host::-webkit-scrollbar-thumb {
		background-color: #2296f3;
	}
}

#filter-activity-type,
#filter-company,
#filter-working-centre,
#filter-activity-status,
#activity-company,
#activity-type,
#activity-working-center,
#activity-user,
#activity-status {
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		white-space: initial !important;
	}
}

#activity-calendar div.fc-view-container div table tbody tr td .fc-scroller,
#activity-list-main .card-content .card-body {
	overflow: unset !important;
	height: auto !important;
}

#activity-calendar .fc-button-group .fc-button-active {
	background-color: #0A8AEC !important;
}

.bullets-group-1 {
	overflow: hidden;

	div {
		float: left;

		@media (min-width: 1024px) {
			margin-right: 11px;
		}
	}

	@media (min-width: 1280px) {
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 12px;
	}
}

.cdk-global-scrollblock {
	overflow-y: unset;
}

#filter-activity-status .ng-dropdown-panel .ng-dropdown-panel-items {
	height: 222px;
}

// For the pdfs with the class 'pdf-template'
.pdf-template {
	$blue-variant-1: #0070c0;
	$blue-variant-2: #365f91;

	.blue-variant-1 {
		color: $blue-variant-1;
	}

	.blue-variant-2 {
		color: $blue-variant-2;
	}

	.text-decoration-underline {
		text-decoration: underline;
	}

	>* {
		font-family: 'GesemeAtypBL', sans-serif;
		font-size: 13px;
		color: #333;
	}

	.chartjs-container {
		width: 920px;
	}

	.title-pg {
		border-bottom: 2px solid $blue-variant-2;

		h4 {
			color: $blue-variant-2;
		}
	}

	ul,
	li {
		list-style-type: square;
		padding: 5px 3px;
		margin: 5px;
	}

	.tbl-client-data {
		border: 2px solid black;

		tr td {
			padding: 5px;
		}

		.first-row td {

			&:first-child,
			&:nth-child(2) {
				background: #cecece;
				width: 10%;
			}
		}

		.row-normal td {
			&:first-child {
				background: #cecece;
				width: 15%;
			}
		}
	}

	table {
		&.bordered {

			td,
			th {
				border: 0.1px solid #808080;

				td {
					border: none;
				}
			}
		}
	}

	&.pdf-annual-shipping-micro {

		// Only for the 'pdf-annual-shipping-micro'
		.header {
			>* {
				font-size: 12px;
				color: #808080;
			}

			.title-text,
			.page-counter {
				font-size: 10px;
			}
		}

		.cover-page {
			.pdf-title {
				font-size: 24px;
			}

			.company-details {
				font-size: 18px;
			}
		}
	}

	&.pdf-memoria-annual {

		// Only for the 'pdf-memoria-annual'
		.header {
			>* {
				font-size: 12px;
				color: #808080;
			}

			.title-text {
				font-size: 14px;
			}
		}

		.cover-page {
			.pdf-title {
				font-size: 32px;
			}

			.period {
				font-size: 18px;
			}

			.company-details {
				font-size: 24px;
			}

			.date-written {
				font-style: italic;
			}
		}
	}
}

#ActuaciónModal {
	z-index: 3000;
	background: radial-gradient(#5e5d5d, transparent);
}

// tooltip info icon
.tippy-popper {
	.tippy-tooltip.info-tippy-theme {
		color: #fff;
		box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
		padding: 10px;
		background-color: #77c9fa;
		cursor: pointer;

		&[x-placement^='right'] {
			.tippy-arrow {
				border-right: 8px solid #77c9fa;
			}
		}

		&[x-placement^='bottom'] {
			.tippy-arrow {
				border-bottom: 8px solid #77c9fa;
			}
		}

		label {
			color: #fff;
		}

		.tippy-content table tr td {
			border: 1px solid #fff;
			padding: 5px;
		}
	}
}

.iframe {
	border: 2px solid !important;
	border-color: #f0f0f0 !important;
}

.iframe-boder {
	height: 100% !important
}

.modal-open .modal:has(#modal-annual-report-1) {
	overflow-y: hidden !important;
}

.tbl-row-action {
	color: #005bef;
}

.modal-backdrop {
	/* bug fix - no overlay */
	display: none;
}

.e-dialog .e-dlg-header {
	display: block;
	font-size: 18px;
	font-weight: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: top;
	white-space: nowrap;
	width: 95%;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
	background: $base-color;
	color: #fff;
	opacity: 1;
}

.e-pager .e-currentitem.e-numericitem.e-focused {
	background: $base-color;
	box-shadow: none;
}

.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
	color: $base-color;
}

.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus .e-acrdn-header-icon .e-icons {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-acrdn-header-content {
	color: rgba(0, 0, 0, 0.87);
}

.e-accordion .e-acrdn-item.e-overlay.e-overlay.e-select.e-expand-state .e-acrdn-header .e-icons {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-overlay.e-overlay.e-select.e-expand-state .e-acrdn-header .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header:hover .e-acrdn-header-icon .e-icons,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header:hover .e-acrdn-header-icon .e-icons {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header:hover .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header:hover .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header:hover:focus .e-icons,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header:hover:focus .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header:hover:focus .e-icons,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header:hover:focus .e-acrdn-header-content {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header .e-acrdn-header-icon {
	color: $base-color;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state>.e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state>.e-acrdn-header .e-acrdn-header-content {
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline.e-focused.e-active .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-outline.e-focused.e-active .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary.e-outline,
.e-chip-list .e-chip.e-primary.e-outline {
	background: transparent;
	border-color: $base-color;
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline .e-chip-delete,
.e-chip-list .e-chip.e-primary.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline .e-chip-delete {
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-outline .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover,
.e-chip-list .e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline:hover,
.e-chip-list .e-chip.e-primary.e-outline:hover {
	background: rgba(43, 22, 227, 0.12);
	border-color: $base-color;
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-delete,
.e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-delete {
	color: $base-color;
}

.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary.e-outline.e-focused .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-outline.e-focused .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary.e-focused.e-active .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-focused.e-active .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary.e-focused .e-chip-avatar,
.e-chip-list .e-chip.e-primary.e-focused .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-chip.e-primary,
.e-chip-list .e-chip.e-primary {
	background: $base-color;
	border-color: transparent;
	color: #fff;
}

.e-chip-list.e-selection .e-chip.e-active.e-outline.e-focused .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-selection .e-chip.e-active.e-outline {
	background: $base-color;
	border-color: $base-color;
	color: #fff;
}

.e-chip-list.e-selection .e-chip.e-active.e-focused .e-chip-avatar {
	background-color: $base-color;
	color: #fff;
}

.e-chip-list.e-selection .e-chip.e-active {
	background: $base-color;
	border-color: transparent;
	color: #fff;
}

.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active .e-switch-on {
	background-color: $base-color;
}

.e-switch-wrapper.e-rtl:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-handle.e-switch-active {
	background-color: $base-color;
}

.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
	background-color: $base-color;
}

.e-switch-wrapper.e-rtl .e-switch-on,
.e-css.e-switch-wrapper.e-rtl .e-switch-on {
	background-color: $base-color;
}

.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
	background-color: $base-color;
	color: #fff;
}

.e-switch-wrapper:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper:hover .e-switch-handle.e-switch-active {
	background-color: $base-color;
}

.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on {
	background-color: $base-color;
	color: #fff;
}

.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
	background-color: $base-color;
}

.e-radio:checked:focus+label::before {
	border-color: $base-color;
}

.e-radio:checked:focus+label::after {
	background-color: $base-color;
}

.e-radio:checked+label:hover::before {
	border-color: $base-color;
}

.e-radio:checked+label:hover::after {
	background-color: $base-color;
}

.e-radio:checked+label::before {
	background-color: #fff;
	border-color: $base-color;
}

.e-radio:checked+label::after {
	background-color: $base-color;
	color: $base-color;
}

.e-radio:focus+label::before {
	border-color: $base-color;
	box-shadow: none;
}

.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
	background-color: $base-color;
	border-color: transparent;
	color: #fff;
}

.e-checkbox-wrapper .e-checkbox:active+.e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:active+.e-frame.e-check {
	background-color: $base-color;
	border-color: transparent;
	box-shadow: none;
	color: #fff;
}

.e-checkbox-wrapper .e-checkbox:focus+.e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:focus+.e-frame.e-check {
	background-color: $base-color;
	border-color: transparent;
	box-shadow: none;
	color: #fff;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
	background-color: $base-color;
	border-color: transparent;
	color: #fff;
}

.e-bigger .e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check {
	background-color: $base-color;
	border-color: transparent;
}

.e-bigger.e-small .e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small.e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-frame:hover.e-check {
	background-color: $base-color;
	border-color: transparent;
}

.e-btn.e-outline.e-primary,
.e-css.e-btn.e-outline.e-primary {
	background: transparent;
	border-color: $base-color;
	color: $base-color;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
	background: transparent;
	border-color: transparent;
	color: $base-color;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
	background: rgba(73, 22, 227, 0.04);
	border-color: transparent;
	color: $base-color;
}

.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus {
	background: rgba(73, 22, 227, 0.12);
	border-color: transparent;
	color: $base-color;
}

.e-btn.e-flat.e-primary:active,
.e-btn.e-flat.e-primary.e-active,
.e-css.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary.e-active {
	background: rgba(73, 22, 227, 0.24);
	border-color: transparent;
	color: $base-color;
}

.e-btn.e-round.e-primary:hover,
.e-btn.e-round-edge.e-primary:hover,
.e-css.e-btn.e-round.e-primary:hover,
.e-css.e-btn.e-round-edge.e-primary:hover {
	border-color: $base-color;
}


.e-listbox-wrapper.e-sortableclone .e-list-badge,
.e-listbox-container.e-sortableclone .e-list-badge,
.e-listboxtool-wrapper.e-sortableclone .e-list-badge {
	-ms-flex-align: center;
	align-items: center;
	background-color: $base-color;
	border: 1px solid #fff;
	border-radius: 50%;
	color: #fff;
	display: -ms-flexbox;
	display: flex;
	font-size: 12px;
	height: 22px;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	right: -10px;
	top: -10px;
	width: 22px;
}

.e-listbox-wrapper .e-placeholder,
.e-listbox-container .e-placeholder,
.e-listboxtool-wrapper .e-placeholder {
	background-color: $base-color;
	display: block;
	height: 1px;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus {
	background-color: #e0e0e0;
	box-shadow: none;
	color: $base-color;
	border: 1px solid transparent;
}

.e-popup.e-multi-select-list-wrapper.e-multiselect-group .e-list-group-item.e-active.e-item-focus {
	background-color: #e0e0e0;
	box-shadow: none;
	color: $base-color;
	border: 1px solid transparent;
}

.e-float-input.e-input-group.e-ddl.e-control.e-icon-anim>.e-float-text,
.e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard>.e-float-text {
	color: $base-color;
}

.e-dropdownbase .e-list-item.e-active.e-hover {
	color: $base-color;
}

.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active,
.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active.e-hover {
	background-color: #eee;
	border-color: #fff;
	color: $base-color;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
	background-color: #eee;
	border-color: #fff;
	color: $base-color;
}

.e-mention .e-mention-chip,
.e-mention .e-mention-chip:hover {
	background: #eee;
	border-radius: 2px;
	border: none;
	color: $base-color;
	cursor: default;
}

.e-mention .e-mention-chip::selection {
	color: #fff;
	background: $base-color;
}

.e-spinner-pane.e-spin-overlay .e-spinner-inner .e-spin-bootstrap {
	fill: $base-color;
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-arc {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-arc {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-arc {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-material,
.e-spinner-pane .e-spinner-inner .e-spin-material3 {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 {
	stroke: $base-color;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap {
	fill: $base-color;
	stroke: $base-color;
}
.e-mention .e-mention-chip,
.e-mention .e-mention-chip:hover {
	background: #eee;
	border-radius: 2px;
	border: none;
	color: $base-color;
	cursor: default;
}
.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active,
.e-ddl.e-popup.e-multiselect-group .e-list-group-item.e-active.e-hover {
	background-color: #eee;
	border-color: #fff;
	color: $base-color;
}
.e-float-input.e-input-group.e-ddl.e-control.e-icon-anim>.e-float-text,
.e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard>.e-float-text {
	color: $base-color;
}
.e-listbox-wrapper .e-placeholder,
.e-listbox-container .e-placeholder,
.e-listboxtool-wrapper .e-placeholder {
	background-color: $base-color;
	display: block;
	height: 1px;
}

.e-listbox-wrapper.e-sortableclone .e-list-badge,
.e-listbox-container.e-sortableclone .e-list-badge,
.e-listboxtool-wrapper.e-sortableclone .e-list-badge {
	-ms-flex-align: center;
	align-items: center;
	background-color: $base-color;
	border: 1px solid #fff;
	border-radius: 50%;
	color: #fff;
	display: -ms-flexbox;
	display: flex;
	font-size: 12px;
	height: 22px;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	right: -10px;
	top: -10px;
	width: 22px;
}

.e-listbox-wrapper .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listbox-wrapper .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check,
.e-listbox-container .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listbox-container .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check,
.e-listboxtool-wrapper .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listboxtool-wrapper .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check {
	background-color: $base-color;
	border-color: transparent;
	color: #fff;
}

.e-mention .e-mention-chip,
.e-mention .e-mention-chip:hover {
	background: #eee;
	border-radius: 2px;
	border: none;
	color: $base-color;
	cursor: default;
}

.e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-input-focus.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: $base-color;
  box-shadow: inset 1px 1px $base-color, inset -1px 0 $base-color, inset 0 -1px $base-color;
}
.e-outline.e-input-focus label.e-float-text::before,
.e-outline.e-input-focus label.e-float-text::after,
.e-outline.e-float-input.e-input-focus label.e-float-text::before,
.e-outline.e-float-input.e-input-focus label.e-float-text::after,
.e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::before,
.e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::after {
  border-top-color: $base-color;
}
.e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left) {
  border-top-color: transparent;
  box-shadow: inset 1px 0 $base-color, inset -1px 0 $base-color, inset 0 -1px $base-color;
}
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
  background: $base-color;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: $base-color;
  color: #fff;
}
.e-float-input.e-small textarea::selection,
.e-float-input textarea::selection {
  background: $base-color;
  color: #fff;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: $base-color;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
  background: $base-color;
}
.e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: $base-color;
}
.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
  color: $base-color;
}
.e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: $base-color;
  box-shadow: inset 1px 1px $base-color, inset -1px 0 $base-color, inset 0 -1px $base-color;
}
.e-outline.e-float-input.e-input-group.e-valid-input.e-input-focus label.e-label-top,
.e-outline.e-float-input.e-input-group.e-input-focus label.e-label-top,
.e-outline.e-float-input.e-control-wrapper.e-input-group.e-valid-input.e-input-focus label.e-label-top,
.e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus label.e-label-top {
  color: $base-color;
}
.e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
  color: $base-color;
}
.e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::before,
.e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
.e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::before,
.e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after {
  background: $base-color;
}

.e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
.e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
.e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before,
.e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
  background: $base-color;
}
.e-slider-tooltip.e-tooltip-wrap.e-popup {
	background-color: $base-color;
	border: 0;
	border-radius: 8px;
	/* stylelint-disable */
	cursor: grab;
	/* stylelint-enable */
	opacity: 1;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-inner {
	color: $base-color;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-bottom {
	border-top-color: $base-color;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-top {
	border-bottom-color: $base-color;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-right {
	border-left-color: $base-color;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-left {
	border-right-color: $base-color;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default {
	background-color: $base-color;
	border: 0;
	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 0%;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
	/* stylelint-disable */
	cursor: grab;
	/* stylelint-enable */
	opacity: 1;
  }

  .btn-secondary {
	background-color: gray;
	border-color: gray;
	color: white;
  }